@import '../variables/variables.less';
//@import '../style-guide/components/platform-switcher.less';
//@import "../mixins/abtest";


//
//	GLOBAL
//
h2 {
	margin-bottom: 16px;

	@media @desktop {
		font-size: 46px;
		line-height: 60px;
	}
}

h3 {
	font-size: 32px;
	line-height: 48px;
}

// text below H2
p.subh2 {
	margin-bottom: 0;

	@media @desktop {
		font-size: 18px;
		line-height: 24px;
	}
}


.text-bold {
	font-weight: bold;
}

.signal-link {
	color: @Grey-1;
	text-decoration: underline;

	&:focus,
	&:hover {
		color: @Blue-v2;
		text-decoration: none;
	}
}


//
//	Sticky bar
//
.sticky-bar {
	.button {
		height: 48px;
		padding-left: 48px;

		background-image: url(../../i/buttons/win-white.svg);
		background-position: 12% 48%;
		background-size: 16px 16px;
		background-repeat: no-repeat;

		border-radius: 6px;

		&:hover,
		&:focus {
			background-image: url(../../i/buttons/win-white.svg) !important;
		}


		span {
			font-size: 14px;
			font-weight: 700;
			line-height: 16px;
			letter-spacing: 0.6px;

			@media @mobile {
				padding-top: 5px;
			}
		}
	}
}

.sticky-bar__badge {
	span:not(.sticky-bar__badge-note) {
		position: relative;
		top: 8px;
		margin-left: 8px;
		font-size: 12px;
		line-height: 18px;
		text-align: left;
	}

	.sticky-bar__badge-note {
		font-size: 16px;
		line-height: 22px;

		&::before {
			content: '';
			margin: 0 16px;
		}
	}
}


//
//	Media object
//
.media-object {
	.image {
		text-align: center;
	}

	.text {
		align-content: center;
	}

	h2 {
		@media @mobile {
			text-align: center;
		}
	}

	p.subh2 {
		text-align: left;

		@media @mobile {
			text-align: center;
		}
	}

	.button-wrapper {
		margin-top: 40px;

		@media @mobile {
			margin-top: 32px;
		}

		.button {
			margin-bottom: 0;
			
			span {
				box-shadow: none !important;
			}
		}
	}
}


//
//	Header
//

// Logo
.bi-nav-toplink {
	position: relative;
	top: -4px;

	@media @tablet {
		margin-top: 4px;
	}

	@media @desktop, @desktop-large {
		margin-top: 0;
		top: -8px;
	}

	@media @desktop-xxl {
		margin-top: 0;
		top: -6px;
		left: -58px;
	}
}


//
//	Hero
//
#top {
	padding-top: 64px;
	padding-bottom: 0;

	text-align: center;
	color: @White;

	background: #2C2930 url("../../i/page/free-antivirus-download/bg-hero-gloweb7524.jpg") 50% 50% no-repeat;
	background-size: cover;

	@media @mobile {
		padding-top: 48px;
	}

	.product-name.text-normal {
		font-size: 14px;
		line-height: 20px;

		img {
			margin-right: 8px;
		}
	}

	.hero-headline {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 1.5em;

		@media @mobile {
			display: block;
		}

		img {
			margin-right: 1.5rem;
			@media @mobile {
				margin-right: 0;
				margin-bottom: 1.5rem;
			}
		}
	}

	h1 {
		margin: 0;
		color: @White;
		font-size: 56px;
		line-height: 64px;

		@media @mobile {
			font-size: 36px;
			line-height: 1.4;
		}
	}

	.sub-h1 {
		margin-bottom: 40px;
		color: @White-v2;
		line-height: 30px;
	}

	.top-hint {
		margin-top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media @mobile {
			flex-direction: column;
		}

		.top-hint-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			justify-content: center;
			gap: 24px;
		
			@media @mobile {
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 12px;

				img {
					margin-right: 8px;
				}
			}
		}

		.top-hint-award {
			display: flex;
			align-items: center;

			@media @mobile {
				margin-bottom: 16px;
			}

			&:first-child {
				@media @mobile {
					margin-left: 22px;
				}
			}
		}

		.top-hint-text {
			//...
		}

		.trustpilot {
			width: 420px;
			height: 20px;

			@media @mobile {
				margin: 24px 0;
				width: 100%;
			}
		}

		img {
			margin-right: 8px;
			object-fit: contain;
			@media @mobile {
				margin-right: 0;
				margin-left: 0;
			}

		}

		a {
			color: @White;
			border-bottom: 2px dashed @White;;
			text-decoration: none;
		}

		p {
			margin-bottom: 0;
			color: @White-v2;
			@media @mobile {
				color: @Grey-8;
			}
		}

		.top-award-text {
			font-size: 12px;
			line-height: 18px;
			//width: 96px;
			margin-bottom: 0;
			text-align: left;
			color: @White-v2;
			align-content: center;
		}
	}

	.button-wrapper {
		margin-top: 48px;
		margin-bottom: 32px;

		@media @mobile {
			margin-top: 40px;
			margin-bottom: 24px;
		}
	}

	.button {
		margin-bottom: 0;

		&.primary.green.shadow span {
			box-shadow: none;
		}
	}

	.hero-features {
		display: flex;
		gap: 32px;
		justify-content: space-between;
		max-width: 1230px;
		margin-left: auto;
		margin-right: auto;
		padding: 88px 48px 48px 48px;

		@media @mobile {
			flex-wrap: wrap;
			justify-content: center;
			padding-top: 64px;
			gap: 24px;
		}

		.hero-feature {
			display: flex;
			align-items: center;
			text-transform: uppercase;
			font-size: 15px;
			font-weight: 800;
			line-height: 24px;
			letter-spacing: 2.5px;
			text-align: left;
			max-width: 264px;

			img.hero-feature-icon {
				margin-right: 1rem;
			}

			.hero-feature-text {
				max-width: 200px;
				hyphens: auto;
			}
		}
	}
}


//
//	Media #1 – Simple. Strong. Secure
//
.section-antivirus {
	padding: 103px 0 56px;

	@media @mobile {
		padding: 48px 0;
	}

	// Platform icons
	.platforms {
		margin-bottom: 16px;

		@media @mobile {
			text-align: center;
		}

		img {
			margin-right: 12px;

			// Recolor icons to #1C222E
			filter: brightness(0) saturate(100%) invert(13%) sepia(18%) saturate(810%) hue-rotate(181deg) brightness(92%) contrast(98%);
		}
	}

	.image {
		img {
			@media @mobile {
				height: auto;
				margin-bottom: 24px;
			}
		}
	}

	.text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		@media @mobile {
			align-items: center;
		}

		@media @desktop {
			padding-left: 0;
		}
	}
}


//
//	Features
//
.features {
	padding: 32px 0 88px;

	@media @mobile {
		padding: 24px 0 64px;
	}

	.feature-card {
		height: 100%;
		padding: 24px;
		padding-bottom: 32px;

		text-align: center;
		border-radius: 6px;
		border: 1px solid #CED4E0;

		@media @mobile {
			margin-bottom: 24px;
		}

		//img {}

		h3 {
			margin: 8px 0;
			font-size: 16px;
			line-height: 22px;
		}

		p {
			margin-bottom: 0;
			color: #697284;
			line-height: 1.4;
		}
	}
}


//
//	Rating
//
.section-rating {
	padding: 88px 0;
	background-color: #F7F9FC;

	@media @mobile {
		padding: 64px 0;
	}

	.text {
		text-align: center;

		p {
			@media @tablet {
				margin-bottom: 40px;
			}

			@media @desktop {
				padding-left: 140px;
				padding-right: 140px;
				margin-bottom: 56px;
			}
		}
	}

	.rating-card {
		height: 100%;
		padding: 48px 40px;
		border-radius: 10px;
		border: 1px solid #CED4E0;
		background: @White;

		@media @mobile {
			margin-top: 32px;
		}

		// Company logo
		.icon {
			margin-bottom: 32px;
		}

		// Quote symbol
		img[alt=quote] {
			filter: brightness(0) saturate(100%) invert(48%) sepia(57%) saturate(5414%) hue-rotate(198deg) brightness(89%) contrast(90%);
		}

		p {
			text-align: left;
			margin-bottom: 32px;
			margin-top: 12px;

			@media @mobile {
				margin-bottom: 28px;
			}
		}
	}
}


//
//	Media #2 – 6 layers of antivirus security
//
.section-media-2 {
	padding: 64px 0;

	@media @mobile {
		padding: 0 0 48px;
	}

	.button-wrapper {
		@media @mobile {
			text-align: center;
		}
	}

	.image {
		img {
			height: auto;

			@media @mobile {
				margin-top: 48px;
				margin-bottom: 24px;
			}
		}
	}
}


//
//	Feature cards
//
.section-feature-cards {
	padding: 32px 0 48px;

	@media @mobile {
		padding: 24px 0 40px;
	}

	.span4 {
		margin-bottom: 40px;

		@media @mobile {
			margin-bottom: 24px;
		}
	}

	.feature-card {
		display: flex;
		align-items: flex-start;
		gap: 16px;
		height: 100%;
		padding: 24px;
		padding-bottom: 32px;
	
		border-radius: 6px;
		border: 1px solid #CED4E0;
		background: @White;
	
		img {
			//...
		}
	
		.feature-card-text {
			strong {
				//...
			}
		
			div {
				color: #697284;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}


//
//	Awards-card
//
.awards-card {
	padding: 88px 0;
	text-align: center;
	background: @White-v2;

	@media @mobile {
		padding: 64px 0;
	}

	.container {
		//..
	}

	.awards-card-heading {
		img {
			margin-bottom: 8px;
		}

		//h2 {}
		//p {}
	}

	.card {
		text-align: center;

		.d-sm-none {
			@media @mobile, @tablet {
				display: none;
			}
		}

		.img-award {
			margin: 0 96px;

			@media @mobile {
				margin: 0;
			}

			@media @tablet {
				margin: 0 48px;
			}
		}

		.awards-img {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-top: 0;

			@media @mobile {
				flex-direction: column;
				margin-top: 22px;
			}
		}

		.awards-item {
			margin-top: 48px;

			@media @mobile {
				margin-top: 24px;
			}

			img {
				margin-bottom: 12px;
			}

			&:nth-child(3),
			&:nth-child(4) {
				img {
					@media @tablet, @desktop {
						margin-bottom: 32px;
					}
				}
			}

			strong {
				display: block;
			}
		}
	}
}


//
//	BFT section
//
.section-bft {
	padding-top: 88px;
	text-align: center;

	@media @mobile {
		padding-top: 64px;
	}

	.product-title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		@media @mobile {
			flex-direction: column;
		}

		img {
			margin-right: 24px;

			@media @mobile {
				margin-bottom: 16px;
				margin-right: 0;
			}
		}

		h2 {
			margin-bottom: 0;
		}
	}

	p.subh2 {
		margin-top: 16px;
		margin-bottom: 40px;
	}

	.button.primary.green {
		margin-bottom: 0;

		span.text-large {
			font-size: 20px;

			@media @mobile {
				font-size: 16px;
				padding-top: 24px;
				padding-bottom: 24px;
				height: auto;
			}
		}
	}

	.visual {
		margin-top: 88px;

		@media @mobile {
			margin-top: 64px;
		}
	}
}


//
//	System requirments
//
.system-requirments {
	.container {
		padding-top: 56px;
		background: @White-v2;

		@media @mobile {
			padding-top: 32px;
		}

		h3 {
			margin-bottom: 24px;
		}

		a {
			text-decoration: underline;
		}

		p {
			margin-bottom: 0;

			@media @mobile {
				font-size: 14px;
				text-align: left;
			}
		}

		.box {
			border-bottom: 1px solid @Grey-12;
			padding-bottom: 32px;
			padding-top: 32px;

			ul {
				@media @mobile {
					text-align: left;
				}
			}

			&.box-4 {
				border-bottom: none;
			}

			li {
				position: relative;
				padding-bottom: 8px;
				padding-inline-start: 40px;

				@media @mobile {
					font-size: 14px;
				}

				&:before {
					position: absolute;
					top: -1px;
					left: 0;
					content: "";
					display: inline-block;
					width: 24px;
					height: 24px;
					// background: transparent url(../../i/page/free-antivirus-download/icon-check-oval-green.svg) center center no-repeat;
				}

				&:nth-child(1)::before {
					background: transparent url("../../i/page/free-antivirus-download-t1/number-1.png");
				}
				&:nth-child(2)::before {
					background: transparent url("../../i/page/free-antivirus-download-t1/number-2.png");
				}
				&:nth-child(3)::before {
					background: transparent url("../../i/page/free-antivirus-download-t1/number-3.png");
				}
				&:nth-child(4)::before {
					background: transparent url("../../i/page/free-antivirus-download-t1/number-4.png");
				}

			}

			.platform {
				display: flex;
				flex-direction: row;
				gap: 24px;

				@media @mobile {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}

				img {
					margin-bottom: 16px;
				}

				.platform-mobile {
					@media @desktop {
						max-width: 200px;
					}

					@media @mobile {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: flex-start;

						&.ios {
							align-items: center;

							img {
								margin-bottom: 0;
							}
						}

						img {
							margin-right: 16px;
						}

						div:first-child {
							flex-shrink: 0;
						}
					}
				}
			}
		}
	}
}


//
//	FAQ
//
#faq {

	.container {
		padding-top: 88px;
		padding-bottom: 88px;
		background: @White-v2;

		@media @mobile {
			padding-top: 32px;
			padding-bottom: 64px;
		}
	}

	h2 {
		text-align: center;
		margin-bottom: 64px;

		@media @mobile {
			margin-bottom: 24px;
		}
	}

	h4 {
		@media @mobile {
			font-size: 16px;
		}
	}
}


.faq-container {
	position: relative;
	z-index: 10;
	box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
	background-color: @White;
	padding-top: 15px;
	padding-bottom: 15px;

	> .accordion-item {
		&:not(:first-child) {
			border-top: 1px solid @Grey-5;
		}
	}

	// question
	.question {
		position: relative;
		cursor: pointer;

		@media @tablet, @desktop {
			padding: 39px 128px 39px 48px;
		}
		@media @mobile {
			padding: 20px 63px 20px 24px;
		}

		// clicker
		&::after {
			position: absolute;
			content: "";
			display: inline-block;
			border-radius: 5px;
			border: 1px solid @Grey-4;
			background-color: transparent;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: url(../../i/ico/ico-plus-blue.svg);

			@media @tablet, @desktop {
				top: 24px;
				right: 32px;
				width: 56px;
				height: 56px;
			}
			@media @mobile {
				top: 16px;
				right: 16px;
				width: 32px;
				height: 32px;
			}
		}

		&:hover:not(.expand)::after {
			background-color: @Green;
			border: none;
			background-image: url(../../i/ico/ico-plus-white.svg);
		}

		&.expand::after {
			background-color: transparent;
			background-image: url(../../i/ico/ico-close-blue.svg);
		}

		&.expand:hover::after {
			background-color: @Green;
			border: none;
			background-image: url(../../i/ico/ico-close-white.svg);
		}
	}

	// answer
	.answer {
		overflow: hidden;
		transition: max-height 0.3s ease-out; // note that we're transitioning max-height
		height: auto;
		max-height: 0;
		overflow: hidden;

		@media @tablet, @desktop {
			padding: 0 128px 0 80px;
		}
		@media @mobile {
			padding: 0 64px 0 24px;
		}


		h5, li, p {
			@media @mobile {
				font-size: 12px;
				line-height: 18px;
			}
		}

		h5 {
			margin-bottom: 0;
		}

		ol {
			padding-inline-start: 40px;
		}

		ul {
			li {
				padding-bottom: 0;
				padding-left: 40px;
				position: relative;

				&:before {
					content: '·';
					display: inline-block;
					width: auto;
					height: auto;
					color: @Green;
					position: absolute;
					left: 20px;
					font-size: 2.5rem;
					line-height: 23px;
					@media @mobile {
						line-height: 17px;
					}
				}
			}
		}

		p:last-child {
			@media @tablet, @desktop {
				margin-bottom: 39px;
			}
			@media @mobile {
				margin-bottom: 16px;
			}
		}
	}

	// expand
	div.expand + div {
		max-height: 600px;
	}
}


//
//	Blog-posts
//
#blogposts {
	padding-top: 88px;
	padding-bottom: 64px;
	@media @mobile {
		padding-top: 64px;
	}


	.button.secondary > span {
		font-weight: 500;
	}

	.container {
		//overflow-x: hidden;
		padding-bottom: 56px;
		@media @mobile {
			padding-bottom: 48px;
		}
	}


	.post {
		@media @mobile {
			margin-bottom: 24px;
		}
	}

	.row {
		&.blog {
			justify-content: center;
		}
	}


	.carousel-slider {
		.container {
			@media @mobile, @tablet {
				padding-top: 0;
			}
		}
	}

	h2 {
		text-align: left;
		margin-bottom: 0;
		@media @mobile {
			margin-bottom: 24px;
		}
	}

	h4 {
		margin-bottom: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid @Grey-12;

		@media @mobile, @tablet {
			font-size: 16px;
		}
	}

	img {
		width: 100%;

		@media @mobile, @tablet {
			margin-bottom: 16px;
		}
	}

	p {
		color: @Grey-1;
		text-decoration: none;
		font-size: 14px;
	}

	a {
		&:hover {
			text-decoration: none;
		}

		&.button {
			&.secondary {
				span {
					border-bottom: 2px dashed @Blue-v2;
				}
			}
		}
	}

	.link-all {
		margin-bottom: 0;
		@media @tablet, @desktop {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			padding-bottom: 16px;
		}

		a {
			border-bottom: none;

			&.button {
				display: flex;
				justify-content: flex-end;

				@media @mobile {
					justify-content: flex-start;
				}
			}
		}
	}
}


//
//	Locale specific adjustments
//
#top .top-hint .trustpilot {
	@media @tablet, @desktop {
		.lng-de & { width: 510px; }

		.lng-nl &,
		.lng-pl &,
		.lng-ja & { width: 435px; }

		.lng-es &,
		.lng-it &,
		.lng-pt & { width: 450px; }

		.lng-ru & { width: 485px; }
	}
}


//
//	Award tooltip
//
#top .top-hint {
	.top-hint-award:first-child {
		.top-award-text {
			white-space: nowrap;
			width: auto;
		}
	}

	.info-icon {
		border: 1px solid white;
		border-radius: 5rem;
		margin-left: 4px;
		font-size: 10px;
		width: 14px;
		height: 14px;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		cursor: pointer;
	}

	.tooltip-text {
		visibility: hidden;

		position: absolute;
		z-index: 1;
		margin-top: 1.75rem;
		margin-left: -157px;

		@media @mobile {
			margin-left: -234px;
		}


		width: 300px;
		padding: 5px 10px;

		background: #ffffff;
		
		font-size: 12px;
		line-height: 18px;
		text-align: left;
		font-weight: 400;
		white-space: normal;
		color: #697284;

		border-radius: 6px;

		border: 1px solid #CED4E0;

		&::after {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -6px;
			top: -11px;
			border-width: 6px;
			border-style: solid;
			border-color: transparent transparent white transparent;
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -6px;
			width: 0;
			height: 0;
			border-width: 6px;
			border-style: solid;
			border-color: transparent transparent #CED4E0 transparent;
		}
	}

	// Show the tooltip
	.info-icon:hover {
		color: #1E222A;
		background: #ffffff;

		+ .tooltip-text {
			visibility: visible;
		}
	}
}


